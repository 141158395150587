import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Candidate, User } from '@app/core/models';
import { CandidateService, UtilitiesService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import { WorkflowService } from '@app/core/services/workflow.service';
import { select, Store } from '@ngrx/store';
import { groupBy, omit } from 'lodash';
import moment from 'moment';
import { Subject, Subscription, forkJoin } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UserService } from './../../../../../core/services/user.service';
import * as fromSelectors from './../../../../../store/selectors';
import * as fromActions from './../../../../../store/actions/users.action';
import { TenantService } from '@app/core/services/tenant.service';
import { CompanyRule, countriesTypeOptions } from '@app/core/constants/cfao-offer.constants';
import { formatNumber } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-employee-item',
    templateUrl: './employee-item.component.html',
    styleUrls: ['./employee-item.component.scss']
})
export class EmployeeItemComponent implements OnInit, OnChanges, OnDestroy {
    unsubscribe: Subject<void> = new Subject<void>();
    contentLoading = false;
    candidateLoaded = false;
    documentsLoading = false;
    auditLoading = false;
    jobDetailsLoading = false;
    editMode = false;
    showQuickActionsDropdown = false;
    @Input() employeeId;
    @Input() employeeData;
    @Input() employees;
    employee: any = {};
    sections: string[] = ['job', 'personal', 'employment & education', 'answers', 'history'];
    activeSection = 'job';
    documents = {};
    users: User[];
    user: User;
    usersSubscription: Subscription;
    userSubscription: Subscription;
    showUploaderOptions = {
        // 'Employee Documents': {
        //     show: false,
        //     error: null
        // },
        // 'Personal Files': {
        //     show: false,
        //     error: null
        // },
        'Onboarding Documents': {
            show: false,
            error: null
        }
    };
    selectedDocument: any = {};
    uploadedTypesOptions: any[] = [];
    uploadQueue: any[] = [];
    supportedFileTypes: string[];
    documentReviewModal = {
        show: false,
        data: null,
        employeeId: null,
        employee: null
    };
    workflowModal = {
        visible: false,
        options: []
    };
    workflowControl: FormControl;
    terminationModal = {
        visible: false,
        options: [],
        employee: null
    };
    terminationLetterModal = {
        visible: false,
        data: null
    };
    aodSelectDateModal = {
        visible: false,
        data: null
    };
    requestLoading = false;
    documentsTypeOptions: any[] = [];
    acceptedOfferModal: {
        show: boolean;
        data: any;
        workflow?: string;
        workflows?: any[];
        internalDomains?: string[];
    } = {
        show: false,
        data: null,
        workflow: null,
        internalDomains: []
    };
    tenantId: string;
    constructor(
        private userService: UserService,
        private employeeService: EmployeesService,
        private candidateService: CandidateService,
        public utilitiesService: UtilitiesService,
        private store: Store,
        private fb: FormBuilder,
        private workflowService: WorkflowService,
        private utilities: UtilitiesService,
        private tenantService: TenantService,
        private toastr: ToastrService
    ) {
        this.supportedFileTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.oasis.opendocument.text',
            'text/rtf'
        ];
    }
    @ViewChild('uploadOptionsDropUp') uploadOptionsDropUp: ElementRef;
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.uploadOptionsDropUp && !this.uploadOptionsDropUp.nativeElement.children[0].contains(event.target)) {
            this.showUploaderOptions['Onboarding Documents'].show = false;
        }
    }

    ngOnInit(): void {
        // let dropdowns = [
        //     { value: 'SIN', label: 	'Single' },
        //     { value: 'MRI', label: 	'Married in Community' },
        //     { value: 'MRO', label: 	'Married out of Community' },
        //     { value: 'DIV', label: 	'Divorced' },
        //     { value: 'WID', label: 	'Widowed' },
        //     { value: 'PES', label: 	'Permanently Separated' },
        //     { value: 'LIF', label: 	'Life Partner' },
        //     { value: 'MRD', label: 	'Married' },
        // ];
        // this.tenantService.createMaritalStatusOptions({ options: dropdowns });
        this.tenantId = this.utilities.getTenant();
        this.usersSubscription = this.store
            .pipe(select(fromSelectors.getUsersEntities))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((users: User[]) => {
                this.users = [...users];
            });
        this.userSubscription = this.store
            .pipe(select(fromSelectors.getUserEntity))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((user: User) => {
                this.user = user;
            });

        this.store
            .pipe(
                select(fromSelectors.getDocumentTypesData),
                filter((res) => !!res)
            )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response: any) => {
                this.uploadedTypesOptions = [
                    {
                        title: 'Onboarding Documents',
                        types: response
                    }
                ];
            });
        this.store
            .pipe(
                select(fromSelectors.getDocumentTypesData),
                filter((res) => !!res)
            )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (res: any) => {
                    // console.log('documentsTypeOptions', res);
                    this.documentsTypeOptions = res;
                },
                (errorResponse) => {
                    console.error(errorResponse);
                }
            );
    }

    ngOnChanges(changes) {
        // console.log('changes', changes, this.employeeId);
        this.employee = {};
        if (changes.employeeData) {
            this.employee = this.employeeData;
        }
        if (changes.employeeId) {
            if (!this.employee) {
                this.employee = {};
            }
            this.employee.id = this.employeeId;
            this.activeSection = 'job';
            this.contentLoading = true;
            this.loadCandidate();
        }
    }

    editProfile() {
        this.editMode = true;
    }

    onCloseEditMode() {
        this.editMode = false;
        for (let item in this.showUploaderOptions) {
            this.showUploaderOptions[item].show = false;
        }
    }

    processTabs() {
        this.sections = ['job', 'personal', 'employment & education', 'answers', 'history'];
        if (this.user && this.employee) {
            // console.log('processTabs', this.user.role, this.employee.role);
            const securedSections = ['compensation', 'time off', 'files'];
            const allowedRoles = ['account_owner', 'admin', 'hr_admin', 'payroll_business_partner'];
            const protectedRoles = ['account_owner', 'admin', 'hr_business_partner'];
            const kpiSection = ['performance'];
            if (
                (this.user.role === 'hr_business_partner' && !protectedRoles.includes(this.employee.role)) ||
                this.user.id === this.employee.id
            ) {
                allowedRoles.push('hr_business_partner');
                // console.log('hr_business_partner can view complensaton/files');
            }
            if (this.user.role && allowedRoles.includes(this.user.role)) {
                this.sections.splice(3, 0, ...securedSections);
            }
            if (this.employee.kpi?.length) {
                this.sections.splice(4, 0, ...kpiSection);
            }
        }
    }

    async loadCandidate() {
        this.candidateLoaded = false;
        console.time('getDbCandidateFromAPI');
        // const candidate: Candidate = await this.candidateService.getDbCandidate(this.employeeId);
        this.candidateService
            .getDbCandidateFromAPI(this.employeeId)
            .toPromise()
            .then((candidate: Candidate) => {
                if (candidate) {
                    const data = omit(candidate, ['id', 'job_details', 'documents']);
                    this.employee = { ...this.employee, ...data };
                    console.log('Employee ID:', this.employeeId, this.employee);
                }
                this.candidateLoaded = true;
                // Education/Employment fields
                this.transformCandidateDataToForm(this.employee);
                console.timeEnd('getDbCandidateFromAPI');
                this.contentLoading = false;

                // Load job details
                this.loadJobDetails();
            });

        // Load documents
        this.loadDocuments();

        // Load audit
        this.loadAudit();

        // Load answers
        this.employeeService
            .getEmployeeAnswers(this.employee.id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((answers: any) => {
                this.employee.answers = [];
                this.employee.answers = answers;
            });
    }

    async loadDocuments() {
        console.time('loadDocuments');
        this.employeeService.getEmployeeDocumentsFromAPI(this.employee.id).subscribe(
            (documents) => {
                this.groupDocuments(documents);
                this.employee.documents = documents;
                this.documentsLoading = false;
                console.timeEnd('loadDocuments');
            },
            (errorResponse) => {
                this.documentsLoading = false;
                console.error(errorResponse.error);
            }
        );

        // const documents$ = this.employeeService.getEmployeeDocuments(this.employee.id);
        // const candDocuments$ = this.employeeService.getCandidateDocuments(this.employee.id);
        // forkJoin([documents$, candDocuments$])
        //     .pipe(takeUntil(this.unsubscribe))
        //     .subscribe(
        //         ([documents, candDocuments]: any) => {
        //             this.employee.documents = documents || [];
        //             if (
        //                 candDocuments &&
        //                 candDocuments.data &&
        //                 candDocuments.data.find((d) => d.type === 'criminal-check-report')
        //             ) {
        //                 const doc = candDocuments.data.find((d) => d.type === 'criminal-check-report');
        //                 doc.category = 'Onboarding Documents';
        //                 doc.status = 'approved';
        //                 this.employee.documents.unshift(doc);
        //             }
        //             this.groupDocuments(documents);
        //             this.documentsLoading = false;
        //             console.timeEnd('loadDocuments');
        //         },
        //         (errorResponse) => {
        //             console.error(errorResponse.error);
        //             this.documentsLoading = false;
        //         }
        //     );
    }

    async loadAudit() {
        // console.time('loadAudit');
        this.auditLoading = true;
        this.employeeService.getEmployeeAuditFromAPI(this.employee.id).subscribe(
            (audit: any) => {
                console.log('Audit', audit);
                this.employee.audit = audit || [];
                this.auditLoading = false;
                if (this.employee.sync_with_sage && this.employee.sync_with_sage_at) {
                    this.employee.audit.push({
                        created_at: this.employee.sync_with_sage_at,
                        type: 'SAGE'
                    });
                }
                // console.timeEnd('loadAudit');
            },
            (errorResponse) => {
                console.error(errorResponse.error);
                this.auditLoading = false;
            }
        );
    }

    async loadJobDetails() {
        this.jobDetailsLoading = true;
        try {
            console.time('loadJobDetails');
            const jobDetails = await this.employeeService.getJobDetailsFromAPI(this.employee.id);
            if (!this.employee.job_details) {
                this.employee.job_details = {};
            }
            Object.assign(this.employee.job_details, jobDetails);
            if (this.employee.job_details && this.employee.job_details.reporting) {
                const manager = this.employee.job_details.reporting.find((u) => u.relationship === 'Reporting Manager');
                if (manager) {
                    this.employee.reported_chain = manager;
                }
            }
            console.timeEnd('loadJobDetails');
            this.jobDetailsLoading = false;

            this.processTabs();
        } catch (error) {
            this.jobDetailsLoading = false;
            console.error(error);
        }
    }

    private transformCandidateDataToForm(employee): any {
        this.employee.education = (employee.education && employee.education.education_history) || [];
        this.employee.employment_history =
            (employee['work-experience'] && employee['work-experience'].employment_history) || [];
        this.employee.summary = (employee['work-experience'] && employee['work-experience'].employment_summary) || '';
    }

    onChangeSection(section: string) {
        this.activeSection = section;
    }

    groupDocuments(doc) {
        this.documents = groupBy(doc, 'category');
        // if (!this.documents['Employee Documents']) {
        //     this.documents['Employee Documents'] = [];
        // }
        // if (!this.documents['Personal Files']) {
        //     this.documents['Personal Files'] = [];
        // }
        if (!this.documents['Onboarding Documents']) {
            this.documents['Onboarding Documents'] = [];
        }
    }

    documentUpdated(documents) {
        this.groupDocuments(documents);
    }

    employmentSectionUpdated() {
        this.employeeService.getEmploymentDetails(this.employee).then((r: any) => {
            // console.log(r);
            if (r) {
                if (r.education && r.education.education_history) {
                    Object.assign(this.employee.education, r.education.education_history);
                }
                if (this.employee.employment_history) {
                    Object.assign(this.employee.employment_history, r['work-experience'].employment_history);
                }
                this.employee.summary = (r['work-experience'] && r['work-experience'].employment_summary) || '';
                if (this.employee.certificates) {
                    Object.assign(this.employee.certificates, r.certificates);
                }
            }
            // console.log(this.employee);
        });
    }

    onQuickActionsClick(e) {
        e.stopPropagation();
        this.showQuickActionsDropdown = !this.showQuickActionsDropdown;
    }

    onShowWorkflowModal() {
        this.workflowControl = this.fb.control('');
        this.workflowService.getWorkflows().subscribe((workflows: any[]) => {
            // console.log('WORKFLOWS', workflows);
            this.showQuickActionsDropdown = !this.showQuickActionsDropdown;
            this.workflowModal.options = workflows
                .filter(
                    (w: any) =>
                        w?.status === 'live' &&
                        w?.rules?.type === 'manually' &&
                        w.rules.subtype !== 'offer letter' &&
                        w.rules.subtype !== 'offboarding'
                )
                .map((w: any) => ({
                    label: w.title,
                    value: w.id,
                    is_offer_letter: w.rules && w.rules.subtype === 'offer letter' ? true : false
                }));
            this.workflowModal.visible = true;
        });
    }

    onShowOfferLetterModal() {
        forkJoin([this.workflowService.getWorkflows(), this.tenantService.autotags()]).subscribe((response: any[]) => {
            const workflows = response[0];
            const internalDomains = response[1].internal || [];
            this.acceptedOfferModal = {
                show: true,
                data: this.employee,
                workflows,
                internalDomains
            };
            this.showQuickActionsDropdown = !this.showQuickActionsDropdown;
            this.onHideModal();
        });
    }

    onHideModal() {
        this.workflowModal = {
            visible: false,
            options: []
        };
        this.terminationModal = {
            visible: false,
            options: [],
            employee: null
        };
        this.terminationLetterModal = {
            visible: false,
            data: {
                candidate: this.employee
            }
        };
        this.aodSelectDateModal = {
            visible: false,
            data: null
        };
    }

    onApprovalEmployeeOffer() {
        this.acceptedOfferModal.show = false;
    }

    onAddToWorkflow() {
        // console.log('onAddToWorkflow', this.workflowControl.value);
        const option = this.workflowModal.options.find((w) => w.value === this.workflowControl.value);
        if (option.is_offer_letter) {
            this.acceptedOfferModal = {
                show: true,
                data: this.employee,
                workflow: this.workflowControl.value
            };
            this.onHideModal();
        } else {
            this.requestLoading = true;
            this.employeeService.addToWorkflow(this.employee.id, this.workflowControl.value).subscribe(
                () => {
                    this.requestLoading = false;
                    this.onHideModal();
                },
                (errorResponse) => {
                    console.error(errorResponse);
                    alert('Error');
                    this.requestLoading = false;
                    this.onHideModal();
                }
            );
        }
    }

    async onAddEmployeeClick(event) {
        event.stopPropagation();

        this.employee.pending = false;
        this.employee.status = 'active';
        this.showQuickActionsDropdown = false;
        const user: any = await this.userService.getDbUser(this.employee.email);
        if (user) {
            let tenantId = this.utilities.getTenant();
            user.role[tenantId] = 'employee';
            await this.userService.updateDbUser(user.id, { pendingEmployee: false, role: user.role, activated: true });
        }
        this.employeeService.updateEmployeeObj(this.employee.id, { pending: false, status: 'active' });
        this.employeeService.updateEmployee(this.employee.id, { pending: false, status: 'active' });

        this.employee.pending = false;
        this.employee.status = 'active';
        await this.employeeService.updateEmployeeData(this.employee.id, this.employee);
    }

    onAddDocumentBtnClick(key) {
        // console.log('onAddDocumentBtnClick', key, this.showUploaderOptions, this.uploadedTypesOptions);
        for (let item in this.showUploaderOptions) {
            this.showUploaderOptions[item].show = false;
        }
        this.showUploaderOptions[key].show = true;
    }

    onUploadItemClick(type, key) {
        this.selectedDocument = {
            type,
            key
        };
        this.showUploaderOptions[key].show = false;
    }

    isFileSizeValid(size: number) {
        const maxSize = 5 * 1024 * 1024;
        // console.log(size, maxSize);
        return size <= maxSize ? true : false;
    }

    private validateFileType(file: File, types: string[]) {
        return types.indexOf(file.type) !== -1;
    }

    onFileUpload(fileEvent: any) {
        const file = fileEvent.target.files[0];
        const key = this.selectedDocument.key;
        if (this.isFileSizeValid(file.size)) {
            this.showUploaderOptions[key].error = null;
            // console.log('File size is valid');
        } else {
            this.showUploaderOptions[key].error = 'File size is too big';
            setTimeout(() => (this.showUploaderOptions[key].error = null), 10000);
        }
        this.contentLoading = true;
        if (this.validateFileType(file, this.supportedFileTypes)) {
            this.uploadQueue.push({
                file,
                uploadStarted: false,
                uploadFinished: false,
                progress: 0,
                success: false,
                text: file.name,
                type: this.selectedDocument.type,
                category: this.selectedDocument.key,
                extension: this.utilitiesService.getExtension(file.name)
            });
            this.processQueue();
        } else {
            this.showUploaderOptions[key].error = 'Only supported formats are: pdf, doc, docx, rtf, odt';
            this.contentLoading = false;
            setTimeout(() => (this.showUploaderOptions[key].error = null), 10000);
        }
    }

    processQueue() {
        this.uploadQueue.forEach((item) => {
            if (!item.uploadStarted && !item.uploadFinished) {
                this.uploadFile(item);
                const itemInterval = setInterval(() => {
                    if (item.uploadFinished) {
                        const itemIndex = this.uploadQueue.findIndex((ui) => ui.id === item.id);
                        if (itemIndex !== -1) {
                            this.uploadQueue.splice(itemIndex, 1);
                        }
                        clearInterval(itemInterval);
                    }
                });
            }
        });
    }

    uploadFile(item) {
        this.utilitiesService
            .readFile(item.file)
            .then((fileValue) => {
                item.uploadStarted = true;
                const uploadProgressInterval = setInterval(() => {
                    item.progress = item.progress + 1 < 100 ? item.progress + 1 : item.progress;
                }, 400);
                this.employeeService
                    .addDocumentsToEmployee(this.employee.id, {
                        document: fileValue,
                        documentType: item.type,
                        documentCategory: item.category,
                        name: item.text,
                        status: 'approved'
                    })
                    .subscribe(
                        (response: HttpResponse<any>) => {
                            item.progress = 100;
                            item.uploadFinished = true;
                            item.success = true;
                            item.fadeout = true;
                            clearInterval(uploadProgressInterval);
                            const resp: any = response;
                            this.contentLoading = false;
                            const document = this.employee?.documents.find(
                                (d) => d.document_type === resp.employee.document.document_type
                            );
                            if (document) {
                                if (!document.versions) {
                                    document.versions = [];
                                }
                                document.versions.push({
                                    link: document.link,
                                    mime_type: document.mime_type,
                                    original_name: document.original_name,
                                    name: document.name,
                                    uploaded_at: document.uploaded_at
                                });
                                document.link = resp.employee.document.link;
                                document.name = resp.employee.document.name;
                                document.original_name = resp.employee.document.original_name;
                                document.size = resp.employee.document.size;
                                document.uploaded_at = resp.employee.document.uploaded_at;
                            } else {
                                this.employee.documents.push(resp.employee.document);
                            }

                            this.documentUpdated(this.employee.documents);
                            const audit = {
                                created_at: Math.floor(Date.now() / 1000),
                                user_id: this.user.id,
                                type: 'updated',
                                section: 'document',
                                updated: [this.selectedDocument.key]
                            };
                            this.employeeService.saveEmployeeAudit(this.employee.id, audit);
                        },
                        (error) => {
                            console.error(error);
                            item.text =
                                error && error.error && error.error.message ? error.error.error.message : 'Error';
                            item.progress = 100;
                            item.uploadFinished = true;
                            clearInterval(uploadProgressInterval);
                            this.contentLoading = false;
                        }
                    );
            })
            .catch((error) => {
                console.error(error);
                console.error('Error reading uploaded file');
            });
    }

    onShowDocument(document) {
        // console.log(
        //     'onShowDocument document -',
        //     document,
        //     'getExtension -',
        //     this.utilitiesService.getExtension(document.name),
        //     'document.id-',
        //     document.id
        // );
        if (this.utilitiesService.getExtension(document.name) === 'docx') {
            this.contentLoading = true;
            const id = document.id;
            this.employeeService.convertToPDF(this.employee.id, document.link, id).subscribe((response: any) => {
                // console.log('convertToPDF response', response);
                document.link = response.url;
                document.name = response.name;
                this.documentReviewModal = {
                    show: true,
                    data: document,
                    employeeId: this.employeeId,
                    employee: this.employee
                };
                this.contentLoading = false;
            });
        } else if (document.id || document.documentType) {
            this.documentReviewModal = {
                show: true,
                data: document,
                employeeId: this.employeeId,
                employee: this.employee
            };
        }
    }

    onClosePreviewModal() {
        this.documentReviewModal = {
            show: false,
            data: null,
            employeeId: null,
            employee: null
        };
    }

    onDocumentUpdated() {
        console.log('onDocumentUpdated');
    }

    onDownloadDocument(event, file) {
        event.stopPropagation();
        let name = '';
        if (this.employee.employee_id) {
            name = `${this.employee.employee_id}_`;
        }
        let documentType = this.documentsTypeOptions.find((d) => d.value === file.document_type);
        const url = file.link;
        name += `${this.employee.first_name}_${this.employee.last_name}_${
            documentType ? documentType.slug : file.original_name
        }_${moment.unix(file.uploaded_at / 1000).format('YYYYMMDD')}`;
        this.employeeService.downloadFile(url, name).subscribe();
    }

    onShowTerminationModal() {
        this.workflowService.getWorkflows().subscribe((workflows: any[]) => {
            this.showQuickActionsDropdown = !this.showQuickActionsDropdown;
            this.terminationModal.options = workflows
                .filter(
                    (w: any) =>
                        w?.status === 'live' &&
                        w?.rules?.type === 'manually' &&
                        w?.rules?.subtype === 'offboarding' &&
                        (!this.employee.workflows || this.employee.workflows.indexOf(w.id) === -1)
                )
                .map((w: any) => ({ label: w.title, value: w.id }));

            this.terminationModal.visible = true;
            this.terminationModal.employee = this.employee;
        });
    }

    onFinishTermination(event) {
        this.employee.termination_category = event.termination_category;
        this.employee.termination_date = event.termination_date;
        this.employee.termination_reason = event.termination_reason;
        this.onHideModal();
        let tenantId = this.utilities.getTenant();
        if (
            this.employee.termination_category === 'section_197' &&
            (tenantId === 'D9BO1JR3D' || tenantId === 'dimensiondata')
        ) {
            this.terminationLetterModal = {
                visible: true,
                data: {
                    candidate: this.employee
                }
            };
        }
        this.employee.job_details.reporting = event.reporting;
    }

    onCareerPortalProfile() {
        this.contentLoading = true;
        this.candidateService.getCareerPortalProfile(this.employeeId).subscribe((response: any) => {
            // console.log('response', response);
            this.contentLoading = false;
            window.open(response.url, '_blank');
            this.showQuickActionsDropdown = false;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onSyncWithSage() {
        this.saveCFAOCandidate();
    }

    async saveCFAOCandidate() {
        try {
            const data = await this.preparePayload();

            console.log('PAYLOAD', data);
            console.log(JSON.stringify(data));
            this.contentLoading = true;
            this.employeeService.sageCreateEmployee(data).subscribe(
                async (response: any) => {
                    console.log('sageCreateEmployee response', response);
                    if (response.success) {
                        await this.employeeService.updateEmployee(this.employee.id, {
                            sync_with_sage: true,
                            sync_with_sage_at: Math.floor(Date.now() / 1000)
                        });
                        this.employee.sync_with_sage = true;
                    } else {
                        this.toastr.error(response.message || 'Error');
                        await this.employeeService.updateEmployee(this.employee.id, {
                            sync_with_sage_error_at: Math.floor(Date.now() / 1000)
                        });
                        if (response?.message === 'Entity with same ID number already exists') {
                            await this.employeeService.updateEmployee(this.employee.id, {
                                sync_with_sage: true,
                                sync_with_sage_at: Math.floor(Date.now() / 1000),
                                sync_with_sage_error: response.message
                            });
                            this.employee.sync_with_sage = true;
                        }
                    }
                    this.contentLoading = false;
                },
                async (err) => {
                    console.log('sageCreateEmployee Error', err);
                    this.toastr.error('Sync With Sage Error');
                    await this.employeeService.updateEmployee(this.employee.id, {
                        sync_with_sage_error_at: Math.floor(Date.now() / 1000)
                    });
                    this.contentLoading = false;
                }
            );
        } catch (err) {
            this.toastr.error("Employee hasn't completed onboarding");
        }
    }

    async preparePayload() {
        const jobTitle: any = await this.employeeService.getJobTitle(this.employee.job_details.designation);
        const offerApproval: any = await this.employeeService.getEmployeesOfferApproval(this.employee.id);
        const location: any = await this.employeeService.getLocationById(offerApproval.location);
        // console.log('jobTitle and offerApproval', jobTitle, offerApproval, location);
        if (!this.employee.personal) {
            this.employee.personal = {};
        }
        const personal = this.employee.personal;
        if (!this.employee.payroll_settings) {
            this.employee.payroll_settings = {};
        }
        const payrollSettings = this.employee.payroll_settings;
        const bankingDetails = this.employee.banking_details || {};
        const companyRule = CompanyRule.find((c) => payrollSettings.company_rule === c.CompanyRuleCode);
        let gender;
        let countryOfBirth = countriesTypeOptions.find((c) => c.code === this.employee.nationality);
        let racialGroup;
        let addressCountryCode = countriesTypeOptions.find((c) => c.code === this.employee.address?.addressCountry);
        let startDate = moment(this.employee.start_date, 'DD-MM-YYYY').format('YYYY/MM/DD');
        if (moment(this.employee.start_date, 'DD-MM-YYYY').unix() < 1740816146) {
            startDate = '2025/03/01';
        }
        if (this.employee.ethnicity === 'African') {
            racialGroup = 'A';
        } else if (this.employee.ethnicity === 'Indian') {
            racialGroup = 'I';
        } else if (this.employee.ethnicity === 'Coloured') {
            racialGroup = 'C';
        } else if (this.employee.ethnicity === 'White') {
            racialGroup = 'W';
        }
        if (this.employee.gender === 'Male') {
            gender = 'M';
        } else if (this.employee.gender === 'Female') {
            gender = 'F';
        }
        let phone = '';
        if (this.employee.phone) {
            phone = this.employee.phone.replace(/ /g, '');
            if (phone.includes('+27')) {
                phone = phone.replace('+27', '0');
            }
        }
        let initials = '';
        if (this.employee.first_name) {
            initials += this.employee.first_name[0];
        }
        if (this.employee.middle_name) {
            initials += this.employee.middle_name[0];
        }
        if (this.employee.last_name) {
            initials += this.employee.last_name[0];
        }
        const updData: any = {
            CompanyCode: companyRule.CompanyCode.trim(), //Mapping - CompanyRule
            CompanyRuleCode: payrollSettings.company_rule, //Mapping - CompanyRule
            EmployeeCode: this.employee.employee_id.trim(),
            EntityCode: this.employee.employee_id.trim(), //null = system generates code
            GenEntityTypeCode: 'INDV', //Always use this value
            GenEntitySubTypeCode: 'EMPL', //Always use this value
            TitleTypeCode: this.employee.title_type, //Mapping - TitleType ?????
            Gender: gender, //M or F
            Initials: initials,
            FirstName: this.employee.first_name,
            LastName: this.employee.last_name,
            KnownAsName: this.employee.first_name,
            MaritalStatusTypeCode: personal.marital_status, //Mapping - MaritalStatusType
            CountryOfBirth: countryOfBirth ? countryOfBirth.alpha3Code : 'ZAF', //ISO 3 character codes
            LanguageTypeCode: 'ENG', //Can default to ENG or AFR
            NationalityCountryCode: countryOfBirth ? countryOfBirth.alpha3Code : 'ZAF', //ISO 3 character codes
            RacialGroup: racialGroup, //Only A, I, C or W as per BEE rules
            IDNumber: personal.identity_number || null, //Leaving blank for testing // personal.identity_number
            LeavePolicyID: companyRule.LeavePolicyID, //Mapping - CompanyRule
            LeaveStartDate: startDate,
            RemunerationEarnDefID: companyRule.RemunerationEarnDefID, //Mapping - CompanyRule
            PaymentRunDefID: companyRule['PaymentRunDefID ACB'], //Mapping - CompanyRule
            PeriodSalary: this.employee.salary[0].salary.toString(),
            TaxStatusCode: companyRule.DefaultTaxStatus, //Mapping - CompanyRule
            TaxCalculation: companyRule.DefaultTaxCalculation, //Mapping - CompanyRule
            TaxStartDate: startDate, //Greater of the TaxYearStartDate or DateEngaged
            NatureOfContractCode: payrollSettings.nature_of_contract, //Mapping - NatureOfContract
            // "ProbationPeriodEndDate": "2024/05/01",
            DateEngaged: startDate, //Greater of the TaxYearStartDate or DateEngaged
            DateJoinedGroup: startDate, //Same as DateEngaged
            UIFStatusCode: 'UIF', //Always use this value
            UIFStartDate: startDate, //Same as DateEngaged
            MedicalStartDate: startDate, //Same as DateEngaged
            ProvidentFundStartDate: startDate, //Same as DateEngaged
            HomeNumber: phone,
            CellNumber: phone,
            EmailAddress: this.employee.email,
            Addresses: [
                {
                    UseWork: 'False',
                    UsePhysical: 'True',
                    UsePostal: 'False',
                    // UnitPostalNumber: '1204',
                    // "LevelFloor": "3",
                    // "Complex": "Super Fun Complex",
                    CityTown: this.employee.address?.addressLocality || this.employee.address?.addressRegion || 'City',
                    CountryCode: addressCountryCode ? addressCountryCode.alpha3Code : 'ZAF',
                    StreetNumber: this.employee.address?.streetNumber || '1',
                    StreetFarmName:
                        this.employee.address?.streetName || this.employee.location.slice(0, 49) || 'Str Farm',
                    SuburbDistrict: this.employee.address?.addressDistrict || 'District',
                    Province: this.employee.address?.addressRegionShort || 'Province',
                    PostalCode: this.employee.address?.postalCode || '10'
                }
            ],
            BankAccounts: [
                {
                    AccountHolderRelationship: bankingDetails.account_ownership, //O = Own, J = Joint & T = Third Party
                    AccountName: bankingDetails.name,
                    AccountNo: bankingDetails.account_number,
                    AccountTypeCode: bankingDetails.account_type, //CA = Cheque, SA = Savings, TA = Transmission & LA = Loan Account
                    BankCode: bankingDetails.bank, //Mapping - UniversalBankCodes
                    BankBranchCode: bankingDetails.code.toString(), //Mapping - UniversalBankCodes
                    DefaultInd: 'True', //Always True
                    Status: 'A' //Always A for Active
                }
            ],
            Hierarchies: [
                {
                    HierarchyHeaderCode: 'PAYPOINT', //Always use this value. If there is no Paypoint then remove this whole Paypoint section
                    HierarchyCode: payrollSettings.pay_point //Mapping - PAYPOINT
                },
                {
                    HierarchyHeaderCode: 'DEPARTMENT', //Always use this value. If there is no Department then remove this whole Department section
                    HierarchyCode: payrollSettings.department //Mapping - Department
                },
                {
                    HierarchyHeaderCode: 'DIVISION', //Always use this value. If there is no Division then remove this whole Division section
                    HierarchyCode: payrollSettings.division //Mapping - Division
                },
                {
                    HierarchyHeaderCode: 'ESS_LEVELS', //Always use this value.
                    HierarchyCode: payrollSettings.ess_level //Mapping - ESS_Levels (CFAO should have a rule to always select 1 of the 3 values that are part of the mapping)
                }
            ]
        };
        const splitName = this.employee.first_name.trim().split(' ');
        if (splitName.length === 2 && !this.employee.middle_name) {
            updData.FirstName = splitName[0];
            updData.SecondName = splitName[1];
        }
        if (this.employee.middle_name) {
            updData.SecondName = this.employee.middle_name;
        }
        if (this.employee.tax_reference_number) {
            updData.TaxNo = this.employee.tax_reference_number || '';
        }
        if (
            this.employee?.driver_license_number &&
            this.employee?.driver_license_code &&
            this.employee?.driver_valid_from &&
            this.employee?.driver_valid_to
        ) {
            updData.DriversLicenses = [
                {
                    IssueDate: moment(this.employee.driver_valid_from, 'DD-MM-YYYY').format('YYYY/MM/DD'), // ValidFrom
                    ExpiryDate: moment(this.employee.driver_valid_to, 'DD-MM-YYYY').format('YYYY/MM/DD'), // ValidTo
                    LicenseNumber: this.employee.driver_license_number,
                    LicenseCategoriesTypeCode: this.employee.driver_license_code, //Mapping - LicenceCategories
                    CountryCode: 'ZAF' //ISO 3 character codes
                }
            ];
        }
        if (this.employee.passports?.length) {
            let country = countriesTypeOptions.find((c) => c.code === this.employee.passports[0].country_of_issue);
            updData.PassportNo = this.employee.passports[0].number;
            updData.PassportCountryCode = country ? country.alpha3Code : 'ZAF'; //Mandatory if PassportNo is populated. ISO 3 character country code
        }
        if (jobTitle && jobTitle.work_hours) {
            const perDay: number = Math.round(jobTitle.work_hours / 21);
            updData.HoursPerPeriod = formatNumber(jobTitle.work_hours, 'en-US', '1.2-3');
            updData.HoursPerDay = formatNumber(perDay, 'en-US', '1.2-3');
        }
        if (offerApproval && offerApproval.probation && offerApproval.probation !== 'none') {
            const endDate = moment(this.employee.start_date, 'DD-MM-YYYY')
                .add(offerApproval.probation, 'days')
                .format('YYYY/MM/DD');
            updData.ProbationPeriodEndDate = endDate;
            updData.LeaveStartDate = endDate;
        }
        if (personal.date_of_birth) {
            updData.BirthDate = moment(personal.date_of_birth, 'DD-MM-YYYY').format('YYYY/MM/DD');
        }
        if (location) {
            let addressCountryCode = countriesTypeOptions.find((c) => c.code === location.address.addressCountry);
            const locationData: any = {
                UseWork: 'True',
                UsePhysical: 'False',
                UsePostal: 'False',
                SuburbDistrict: location.address?.addressDistrict,
                CityTown: location.address?.addressLocality,
                PostalCode: location.address?.postalCode || '10',
                CountryCode: addressCountryCode ? addressCountryCode.alpha3Code : 'ZAF',
                StreetNumber: location.address?.streetNumber || '1',
                StreetFarmName: location.address?.streetName || location.location.slice(0, 49) || 'Str Farm',
                Province: location.address?.addressRegionShort || location.address?.addressRegion || 'Province'
            };
            updData['Addresses'].push(locationData);
        }
        return updData;
    }

    onViewStatement() {
        this.aodSelectDateModal = {
            visible: true,
            data: this.employee
        };
    }

    async onMakeInactive() {
        this.contentLoading = true;
        const status = 'inactive';
        await this.employeeService.updateEmployee(this.employee.id, {
            status
        });
        this.employee.status = status;
        this.contentLoading = false;
        this.showQuickActionsDropdown = false;
        this.employeeService.updateEmployeeObj(this.employee.id, { status });
        const user = this.users.find((u) => u.id === this.employee.userId);
        user.candidate_status = status;
        this.store.dispatch(new fromActions.UpdateUserSuccess(user));
    }
}
