<div class="audit">
    <ng-container *ngFor="let item of audit; let ind = index">
        <div class="audit-item" *ngIf="item.type === 'delete-workflow'">
            <div class="audit-item-img">
                <img src="assets/images/icons/icons-8-workflow.svg" alt="Workflow Icon" />
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            <strong>
                                <ng-container *ngIf="item?.user?.first_name && item?.user?.last_name"
                                    >{{ item.user.first_name }} {{ item.user.last_name }}</ng-container
                                >
                                <ng-container *ngIf="!item?.user?.first_name && !item?.user?.last_name"
                                    >User</ng-container
                                ></strong
                            >
                            deleted from workflow: <strong>{{ item.workflow_name }}</strong>
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </div>
        <div class="audit-item" *ngIf="item.type === 'offer-letter-workflow'">
            <div class="audit-item-img">
                <img src="assets/images/icons/icons-8-workflow.svg" alt="Workflow Icon" />
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            <strong>
                                <ng-container *ngIf="item?.user?.first_name && item?.user?.last_name"
                                    >{{ item.user.first_name }} {{ item.user.last_name }}</ng-container
                                >
                                <ng-container *ngIf="!item?.user?.first_name && !item?.user?.last_name"
                                    >User</ng-container
                                ></strong
                            >
                            sent a offer letter workflow: <strong>{{ item.workflow_title }}</strong>
                        </p>
                        <p class="audit-activity" *ngIf="!item.completed">
                            Out for signing: {{ item.signing_name }} ({{ item?.role }})
                        </p>
                        <p class="audit-activity" *ngIf="item.completed">
                            Complete
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </div>
        <div class="audit-item" *ngIf="item.type === 'SAGE'">
            <div class="audit-item-img">
                <img style="width: 14px;" src="/assets/images/icons/two-thin-arrows-forming-a-circle.svg" />
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>Sync with Sage</p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </div>
        <div class="audit-item" *ngIf="item.type === 'workflow'">
            <div class="audit-item-img">
                <img src="assets/images/icons/icons-8-workflow.svg" alt="Workflow Icon" />
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p *ngIf="item.workflow_type === 'one-off'">
                            Sent a once-off workflow: <strong>{{ item.workflow_name }}</strong>
                            <span class="reset-email-block ng-star-inserted">
                                <span class="dots"><i></i><i></i><i></i></span>
                                <div class="dropdown" *ngIf="!emailSent">
                                    <button (click)="onResendEmail(item)" class="btn btn-dropdown">
                                        Resend the notification
                                    </button>
                                </div>
                            </span>
                        </p>
                        <p *ngIf="item.workflow_type === 'manually'">
                            <strong>
                                <ng-container *ngIf="item?.user?.first_name && item?.user?.last_name"
                                    >{{ item.user.first_name }} {{ item.user.last_name }}</ng-container
                                >
                                <ng-container *ngIf="!item?.user?.first_name && !item?.user?.last_name"
                                    >User</ng-container
                                ></strong
                            >
                            sent a manual workflow: <strong>{{ item.workflow_name }}</strong>
                            <span class="reset-email-block ng-star-inserted">
                                <span class="dots"><i></i><i></i><i></i></span>
                                <div class="dropdown" *ngIf="!emailSent">
                                    <button (click)="onResendEmail(item)" class="btn btn-dropdown">
                                        Resend the notification
                                    </button>
                                </div>
                            </span>
                        </p>
                        <p *ngIf="item.workflow_type === 'ongoing'">
                            Matched criteria for ongoing workflow: <strong>{{ item.workflow_name }}</strong>
                            <span class="reset-email-block ng-star-inserted">
                                <span class="dots"><i></i><i></i><i></i></span>
                                <div class="dropdown" *ngIf="!emailSent">
                                    <button (click)="onResendEmail(item)" class="btn btn-dropdown">
                                        Resend the notification
                                    </button>
                                    <!-- <button class="btn btn-dropdown">Copy action URL</button> -->
                                </div>
                            </span>
                        </p>
                        <p class="audit-activity">
                            Workflow Notification email: Sent ({{
                                item.created_at_formatted
                                    | amParse: 'DD-MM-YYYY HH:mm'
                                    | amDateFormat: 'YYYY/MM/DD h:mma [UTC+02:00]'
                            }})
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </div>
        <div class="audit-item" *ngIf="item.type === 'tag'">
            <div class="audit-item-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                    <path
                        fill="#637381"
                        d="M7.438 0c-.403 0-.786.16-1.063.445L.437 6.43c-.578.582-.578 1.535 0 2.117l4.016 4.015c.582.579 1.535.579 2.117 0l5.989-5.937c.28-.281.441-.664.441-1.063V1.5c0-.824-.676-1.5-1.5-1.5H7.437zm0 1H11.5c.281 0 .5.219.5.5v4.063c0 .132-.055.261-.148.355l-5.985 5.937c-.199.2-.508.196-.703 0l-4.016-4.02c-.203-.198-.203-.503-.003-.702l5.937-5.985c.094-.093.223-.148.356-.148zM10.5 2c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5z"
                    />
                </svg>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            <strong>{{ item?.user?.full_name || item?.user?.first_name }} </strong>
                            <ng-container *ngIf="item.param === 'added'">added</ng-container>
                            <ng-container *ngIf="item.param === 'deleted'">deleted</ng-container> a tag
                            <span class="tag" [style.background]="item.color">{{ item.title }}</span>
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </div>
        <div class="audit-item" *ngIf="item.type === 'updated'">
            <div class="audit-item-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
                    <path
                        fill="#384E5D"
                        d="M2.475 0c-.823 0-1.5.677-1.5 1.5v9c0 .823.677 1.5 1.5 1.5H5v-1H2.475c-.282 0-.5-.219-.5-.5v-9c0-.281.218-.5.5-.5h4.5v3h3v1h1V3.293L7.682 0H2.475zm5.5 1.707L9.268 3H7.975V1.707zm4.207 4.297c-.33.002-.657.13-.903.38l-4.623 4.704-.705 2.963 2.961-.705.1-.098 4.605-4.523c.504-.493.508-1.317.01-1.815l-.535-.535c-.25-.25-.58-.373-.91-.371zm.005.994c.07 0 .141.027.198.084l.535.535c.112.112.112.281-.002.393v.002l-4.506 4.424-1.111.265.263-1.11 4.428-4.505.002-.002c.055-.057.124-.086.194-.086z"
                    />
                </svg>
            </div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            <strong *ngIf="item.section === 'job'">Job Details</strong>
                            <strong *ngIf="item.section === 'personal'">Personal Details</strong>
                            <strong *ngIf="item.section === 'compensation'">Compensation Details</strong>
                            <strong *ngIf="item.section === 'document'">Document </strong>
                            <strong *ngIf="item.section === 'time_off'">Time Off </strong>
                            updated by <strong>{{ item?.user?.full_name || item?.user?.first_name }}</strong>
                        </p>
                        <p class="audit-activity">
                            <ng-container *ngFor="let field of item.updated; let isLast = last"
                                >{{ field }}<span *ngIf="!isLast">, </span>
                            </ng-container>
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </div>
        <div class="audit-item" *ngIf="item.type === 'unknown'">
            <div class="audit-item-img"></div>
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p><strong>New Employee Form</strong> added by <strong>Employee</strong></p>
                    </div>
                    <em class="date">21 June 2020 at 08:36</em>
                </div>
            </div>
        </div>
        <div class="audit-item" *ngIf="item.type === 'apply-event'">
            <div class="audit-item-content">
                <div class="audit-item-header">
                    <div class="audit-item-heading">
                        <p>
                            <strong>{{ employee?.full_name || employee?.first_name || 'User' }} </strong>
                            has registered for <strong>{{ item.title }}</strong>
                        </p>
                    </div>
                    <em class="date">{{ item.created_at_rel }}</em>
                </div>
            </div>
        </div>
    </ng-container>
</div>
